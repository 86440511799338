.Instruction {
  margin: 20px auto;
  text-align: left;
  line-height: 2rem;
  box-sizing: border-box;
}

.Step {
  margin-top: 10px;
  font-size: 1.2rem;
  text-decoration: underline;
}

.Image {
  width: 100%;
}

.SupportEmail {
  color: var(--textMainColor);
}
