.IncludedConversion {
  border: 1px solid rgb(184, 184, 184);
  border-radius: 3px;
  margin: 10px;
  padding: 5px;
  text-align: center;
  position: relative;
}

.NumberOfOrders {
  font-size: 1.3rem;
  color: var(--textMainColor);
}

.Revenue {
  font-size: 1.5rem;
  margin-top: 5px;
  color: var(--textMainColor);
}

.ErrorIcon {
  position: absolute;
  top: -10px;
  right: -10px;
}
