@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap);
* {
  box-sizing: border-box;
}

html {
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  background: #121212;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --backgroundMainColor: #424242;
  --backgroundSecColor: #121212;
  --textMainColor: #90caf9;
  --textMainColorHover: #3980bb;
  --textSecColor: #f48fb1;
  --textSecColorHover: #ff4382;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.column-10 {
  float: left;
  width: 10%;
}

.column-20 {
  float: left;
  width: 20%;
}

.column-30 {
  float: left;
  width: 30%;
}

.column-40 {
  float: left;
  width: 40%;
}

.column-50 {
  float: left;
  width: 50%;
}

.column-60 {
  float: left;
  width: 60%;
}

.column-70 {
  float: left;
  width: 70%;
}

.column-80 {
  float: left;
  width: 80%;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
  color: #fff;
}

.Toolbar_Toolbar__cZlRD {
    height: 56px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--backgroundMainColor);
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    -webkit-align-content: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 100;
}

.Toolbar_Toolbar__cZlRD nav {
    height: 100%;
}

.Toolbar_Logo__11RPj { 
    width: 100px;
}

@media (max-width: 499px) {
    .Toolbar_DesktopOnly__2XieZ {
        display: none;
    }
}
.NavigationItems_NavigationItems__2sWq3 {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;
}

@media (min-width: 500px) {
    .NavigationItems_NavigationItems__2sWq3{
        flex-flow: row;
    }
}
.NavigationItem_NavigationItem__1enUZ {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
}

.NavigationItem_NavigationItem__1enUZ a {
  color: #d3d3d3;
  text-decoration: none;
  width: 100%;
  display: block;
  box-sizing: border-box;
}

.NavigationItem_NavigationItem__1enUZ a:hover,
.NavigationItem_NavigationItem__1enUZ a:active,
.NavigationItem_NavigationItem__1enUZ a.NavigationItem_active__2qkmh {
  color: var(--textMainColor);
  border-bottom: 2px solid var(--textMainColor);
}

@media (min-width: 500px) {
  .NavigationItem_NavigationItem__1enUZ {
    margin: 0;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
  }

  .NavigationItem_NavigationItem__1enUZ a {
    color: #d3d3d3;
    text-decoration: none;
    padding: 16px 10px;
    border-bottom: 4px solid transparent;
  }

  .NavigationItem_NavigationItem__1enUZ a:hover,
  .NavigationItem_NavigationItem__1enUZ a:active,
  .NavigationItem_NavigationItem__1enUZ a.NavigationItem_active__2qkmh {
    color: var(--textMainColor);
    border-bottom: 4px solid var(--textMainColor);
  }
}

.DrawerToggle_DrawerToggle__3V7hd {
    width: 40px;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    cursor: pointer;
}

.DrawerToggle_DrawerToggle__3V7hd div {
    width: 90%;
    height: 3px;
    background-color: rgba(255, 255, 255, 0.7);
}

@media (min-width: 500px) {
    .DrawerToggle_DrawerToggle__3V7hd {
        display: none;
    }
}
.SideDrawer_SideDrawer__k6WhT {
  position: fixed;
  width: 200px;
  max-width: 70%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 200;
  background-color: var(--backgroundMainColor);
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
    0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  padding: 100px 16px;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
}

@media (min-width: 500px) {
  .SideDrawer_SideDrawer__k6WhT {
    display: none;
  }
}

.SideDrawer_Open__CqjIy {
  transform: translateX(0);
}

.SideDrawer_Close__9pQt_ {
  transform: translateX(-100%);
}

.Backdrop_Backdrop__2fUxl {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgb(0, 0, 0, 0.5);
}
.Layout_Content__PrDi6 {
    margin-top: 72px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    max-width: 1440px;
}
.Card_Card__fEVOp {
  position: relative;
  margin: 0;
  box-shadow: 0 2px 8px rgba(168, 163, 163, 0.26);
  border-radius: 6px;
  padding: 1rem;
  overflow: hidden;
  background: var(--backgroundMainColor);
}

.Review_ReviewCard__2-FQc {
  margin: 2rem auto;
}

.Review_Review__1KZJp {
  padding: 10px;
}

.Review_Header__2av__ {
  margin-top: 0;
  font-size: 1.2rem;
}

.Review_Header__2av__ a {
  text-decoration: none;
  color: var(--textMainColor);
}

.Review_Header__2av__ a:hover {
  color: var(--textMainColorHover);
  border-bottom: var(--textMainColorHover);
}

.Review_VisibleIcon__26eoo {
  margin-left: 20px;
}

.Review_CreatedAt__3lz2l {
  margin: 0 20px;
}

.Review_Feedback__3a3ui {
  margin: 20px 0;
}

.Review_Rating__1CRxR {
  margin: 10px 0;
}

.Review_Image__1bm3b {
  max-width: 300px;
  float: right;
}

@media screen and (max-width: 900px) {
  .Review_TextButtonArea__uOtqB,
  .Review_Image__1bm3b {
    width: 100%;
    margin: 5px auto;
    float: none;
  }
}

.FiveStars_FiveStars__1Gi2t {
  color: var(--textSecColor);
}

.Actions_MoreActions__38kRi {
    margin: 10px 0;
}
.anhnoiButton {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid var(--textSecColor);
  border-radius: 4px;
  background: var(--textSecColor);
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
}

.anhnoiButton:focus {
  outline: none;
}

.anhnoiButton:hover,
.anhnoiButton:active {
  background: var(--textSecColorHover);
  border-color: var(--textSecColorHover);
}

.anhnoiButton--inverse {
  background: transparent;
  color: white;
  border-color: white;
}

.anhnoiButton--inverse:hover,
.anhnoiButton--inverse:active {
  color: white;
  background: #af1347;
}

.anhnoiButton--danger {
  background: #f34343;
  border-color: #f34343;
}

.anhnoiButton--danger:hover,
.anhnoiButton--danger:active {
  background: #830000;
  border-color: #830000;
}

.anhnoiButton:disabled,
.anhnoiButton:hover:disabled,
.anhnoiButton:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.anhnoiButton--small {
  font-size: 0.8rem;
  padding: 0.2rem 1rem;
}

.anhnoiButton--big {
  font-size: 1.5rem;
}

.anhnoiButton--default {
  font-size: 1rem;
}

.Modal_Modal__14T4Z {
  position: fixed;
  z-index: 500;
  background-color: rgb(150, 148, 148);
  width: 70%;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px black;
  padding: 16px;
  left: 15%;
  top: 30%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
  .Modal_Modal__14T4Z {
    width: 500px;
    left: calc(50% - 250px);
  }
}

.ConfirmModal_ConfirmModal__1wxid {
    text-align: center;
}

.ConfirmModal_ConfirmModal__1wxid button {
    margin-top: 10px;
}
.SendReply_TextAreaColumn__16fPn {
  float: left;
  width: 80%;
}

.SendReply_TextAreaColumn__16fPn:hover {
  outline: none;
  border: 1px solid #1c87c9;
}

.SendReply_ButtonsColumn__2eU-l {
  float: left;
  width: 20%;
  padding: 10px;
}

.SendReply_ButtonsColumn__2eU-l button:disabled {
  visibility: hidden;
}

.SendReply_ReplyAction__bZUlk {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  display: block;
}

.SendReply_Row__326aH:after {
  content: "";
  display: table;
  clear: both;
}

.SendReply_Row__326aH {
  margin: 10px 0;
}

.SendReply_SendIcon__obp9Q {
  color: var(--textMainColor);
}

.SendReply_SendIcon__obp9Q:hover {
  color: var(--textMainColorHover);
}

.SendReply_CancelIcon__1lTA9 {
  color: var(--textSecColor);
}

.SendReply_CancelIcon__1lTA9:hover {
  color: var(--textSecColorHover);
}

.ReplyText_ReplyTextRow__1iFvW {
  border-top: 1px solid grey;
  margin: 10px 0;
  padding: 10px;
}

.ReplyText_TextColumn__3NS1F {
  float: left;
  width: 80%;
}

.ReplyText_ButtonsColumn__1vBHx {
  float: right;
  width: 20%;
}

.ReplyText_ButtonsColumn__1vBHx button {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}

.ReplyText_ReplyTextRow__1iFvW:after {
  content: "";
  display: table;
  clear: both;
}

.ReplyText_EditIcon__2OjMe {
  color: var(--textMainColor);
}

.ReplyText_EditIcon__2OjMe:hover {
  color: var(--textMainColorHover);
}

.ReplyText_DeleteIcon__GkB8u {
  color: var(--textSecColor);
}

.ReplyText_DeleteIcon__GkB8u:hover {
  color: var(--textSecColorHover);
}

.Conversion_Conversion__2Q9F2 {
  margin-top: 20px;
}

.Conversion_ClickConversion__2xDIR,
.Conversion_SaleConversion__1eRj7 {
  border: 2px solid grey;
  border-radius: 3px;
  padding: 10px;
  margin-right: 10px;
  text-align: center;
}

.Conversion_DropdownIcon__1M0jj {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  cursor: pointer;
}

.Conversion_DrivenSales__17A8z {
  margin-top: 10px;
  text-align: left;
  padding-left: 20px;
}

.Conversion_Number__2s7Mu {
  color: var(--textMainColor);
}

.Tooltip_Tooltip__sgT7a {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.Tooltip_ShowBorderBottom__2FfK8 {
  border-bottom: 1px dotted rgb(184, 184, 184);
}

.Tooltip_Tooltip__sgT7a .Tooltip_TooltipText__2U0xb {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 0.8rem;

  position: absolute;
  z-index: 1;
}

.Tooltip_Top__3kFIK {
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.Tooltip_Bottom__1Jgik {
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

.Tooltip_Left__1gY1o {
  top: -5px;
  right: 105%;
}

.Tooltip_Right__2KfpH {
  top: -5px;
  left: 105%;
}

.Tooltip_Tooltip__sgT7a:hover .Tooltip_TooltipText__2U0xb {
  visibility: visible;
}

.Feedback_ReadMore__1-Aq6 {
  font-size: 0.9rem;
  margin-left: 10px;
  text-decoration: underline;
  cursor: pointer;
  color: var(--textMainColor);
}

.Image_Image500__1WGaX {
  width: 100%;
  cursor: pointer;
}

.Image_Image500__1WGaX:hover {
  opacity: 0.9;
}

.Image_Image720Area__2i2rM {
  z-index: 500;
  position: fixed;
  width: 70%;
  top: 10%;
  left: 15%;
  text-align: center;
  max-height: 80%;
  overflow: scroll;
}

@media (min-width: 800px) {
  .Image_Image720Area__2i2rM {
    max-width: 500px;
    left: calc(50% - 250px);
  }
}

.Image_Image720__3G7V7 {
  width: 100%;
}
.Video_ThumnailWrapper__3Hz-I {
  position: relative;
}

.Video_ThumnailWrapper__3Hz-I img {
  width: 100%;
  cursor: pointer;
}

.Video_ThumnailWrapper__3Hz-I img:hover {
  opacity: 0.9;
}

.Video_PlayBtn__2Q-MO {
  position: absolute;
  font-size: 48px;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
  cursor: pointer;
}

.Video_VideoWrapper__1vKjD {
  z-index: 500;
  position: fixed;
  width: 70%;
  top: 10%;
  left: 15%;
  text-align: center;
  max-height: 80%;
  overflow: scroll;
}

@media (min-width: 800px) {
  .Video_VideoWrapper__1vKjD {
    max-width: 500px;
    left: calc(50% - 250px);
  }
}

.LatestReviews_Header__2TDip {
    font-size: 1.7rem;
    font-weight: 500;
    margin-bottom: 10px;
    text-align: center;
}

.LatestReviews_LoadMore__206mV {
    text-align: center;
    margin: 20px;
}
.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid var(--textSecColorHover);
  border-color: var(--textSecColorHover) transparent var(--textSecColorHover)
    transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.4);
}

.loading-spinner__overlay-screen {
  position: fixed;
}

.loading-spinner__overlay-area {
  position: absolute;
}

@-webkit-keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.FilterBar_Input__3edTd {
  width: calc(100% - 10px);
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 1rem;
}

.FilterBar_Search__yTTQD {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  color: var(--textMainColor);
}

.FilterBar_Search__yTTQD:hover {
  color: var(--textMainColorHover);
}

.ReviewsContainer_ReviewsContainer__1aDNz {
    max-width: 1000px;
    margin: auto;
}
.DataCard_DataArea__2c3Os {
  height: 20rem;
  max-width: 25rem;
  margin: auto;
}

.DataCard_Header__1TCcF {
  border-bottom: solid 1px rgba(255, 255, 255, 0.7);
  height: 15%;
  text-align: center;
}

.DataCard_Number__2uUTP {
  margin-right: 10px;
  font-size: 30px;
  color: var(--textMainColor);
}

.DataCard_Content__1qaWv {
  height: 70%;
}

.DataCard_Footer__1xdhw {
  border-top: solid 1px rgba(255, 255, 255, 0.7);
  height: 10%;
  text-align: center;
  padding-top: 10px;
}
.LoadingAreaHandler_Error__3VrvO {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 100;
  background-color: #000000c4;
}

.LoadingAreaHandler_ErrorIcon__2pban {
  color: var(--textSecColor);
}

.DrivenSales_TotalRevenue__1kvsG {
  font-size: 3rem;
  text-align: center;
  padding: 10px;
  color: var(--textMainColor);
}

.TimeSelection_TimeSelection__1uR0Y {
  border: none;
  background-color: transparent;
  color: white;
  font-size: 1rem;
  padding: 10px;
  cursor: pointer;
}

.TimeSelection_TimeSelection__1uR0Y option {
  color: black;
}

.IncludedConversion_IncludedConversion__2xDge {
  border: 1px solid rgb(184, 184, 184);
  border-radius: 3px;
  margin: 10px;
  padding: 5px;
  text-align: center;
  position: relative;
}

.IncludedConversion_NumberOfOrders__1k0gm {
  font-size: 1.3rem;
  color: var(--textMainColor);
}

.IncludedConversion_Revenue__Qxn5S {
  font-size: 1.5rem;
  margin-top: 5px;
  color: var(--textMainColor);
}

.IncludedConversion_ErrorIcon__2JPaP {
  position: absolute;
  top: -10px;
  right: -10px;
}

.StarRating_Row__3r-aU {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.StarRating_StarTypeNumber__26O_L {
  margin: 0 5px;
}

.StarRating_StarsNumber__2pgtS {
  width: 15%;
  margin-left: 5px;
}

.StarRating_PercentageNumber__17XCK {
  width: 10%;
  margin-left: 10px;
}

.StarRating_Star__EU5kA {
  color: var(--textMainColor)
}
.PercentageBar_PercentageBar__16XG_ {
  background-color: rgb(51, 51, 51);
  flex: 1 1;
  height: 5px;
  border-radius: 3px;
}

.PercentageBar_Percentage__15kTQ {
  background-color: var(--textMainColor);
  height: 100%;
  border-radius: inherit;
}

.OverallRating_DataContent__2uM4Q {
  padding: 2rem 0;
}

.Overall_DataColumn__2ziMF {
  float: left;
  width: 50%;
  padding: 15px;
  margin-bottom: 20px;
}

.Overall_LatestReviewsColumn__2MFo- {
  float: left;
  width: 100%;
  padding: 15px;
}

.Overall_Row__2xQ5O:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 1300px) {
  .Overall_DataColumn__2ziMF {
    width: 100%;
  }
  .Overall_Row__2xQ5O {
    max-width: 40rem;
    margin: auto;
  }
}

.Settings_Settings__36XVk {
  max-width: 800px;
  margin: auto;
}

.Settings_SettingField__3q1BQ {
  margin-bottom: 40px;
}

.Settings_UpdateAssets__2ZlYm {
  text-align: center;
}

.SettingField_Header__3Jrqp {
  font-size: 1.2rem;
  margin-left: 10px;
  color: var(--textMainColor);
}

.SettingField_SaveBtn__3u05C {
  float: right;
}

.Instruction_Instruction__6lEM4 {
  margin: 20px auto;
  text-align: left;
  line-height: 2rem;
  box-sizing: border-box;
}

.Instruction_Step__3qzIo {
  margin-top: 10px;
  font-size: 1.2rem;
  text-decoration: underline;
}

.Instruction_Image__27pFy {
  width: 100%;
}

.Instruction_SupportEmail__2XK-e {
  color: var(--textMainColor);
}

.UpdateAssets_CloseModalBtn__2NjWP {
  margin-top: 10px;
}

.UpdateAssets_ShowInstruction__37GYD {
  border-bottom: 1px dotted #fff;
  cursor: pointer;
}

.DrivenSale_DrivenSale__1CuGj {
  margin: 2rem auto;
}

.DrivenSale_OrderFrom__1fDda {
    margin-left: 10px;
}

.DrivenSale_OrderName__J8hmu, .DrivenSale_TotalPrice__2IAHd {
  color: var(--textMainColor);
}
.DrivenSales_DrivenSales__3zVi4 {
  max-width: 500px;
  margin: auto;
}

.DrivenSales_Header__-jc1b {
  font-size: 1.7rem;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
}

.DrivenSales_LoadMore__A8rcZ {
  text-align: center;
  margin: 20px;
}

